import { useRouter } from 'next/router'
import NextLink from 'next/link'

import { Flex, Heading } from '@chakra-ui/react'
import CommonButton from '../../components/CommonButton'
import HomeImageSrc from '../../public/images/home/JRODGERS_TUNEBED_082420_SHOT_04_103.jpg'
import Image from 'next/image'

import CommonText from '../../components/CommonText'

const HomeHero = () => {
  const router = useRouter()
  return (
    <Flex direction={['column', 'column', 'row']}>
      <Flex w={['100%', '100%', '50%']}>
        <Flex
          w={['100%']}
          height={{
            sm: '336px',
            md: 'auto',
            lg: '1025px',
          }}
          position="relative"
        >
          <Image
            alt="Woman laying on a TUNE bed"
            layout="fill"
            objectFit="cover"
            src={HomeImageSrc}
            placeholder="blur"
            priority
          />
        </Flex>
      </Flex>
      <Flex
        bgColor="#123631"
        w={['100%', '100%', '50%']}
        color="#EDE7E1"
        justify="center"
        align="center"
        height={{
          sm: '305px',
          md: 'auto',
        }}
      >
        <Flex
          direction={['column']}
          w={['90%', '100%', '90%', '90%', '65%']}
          align="center"
          py={[0, 0, 20]}
          px={[0, 0, 0]}
        >
          <Heading
            mt={[0, 0, 0, 0, 24, 0]}
            mb={{
              sm: '20px',
              md: 6,
              lg: 12,
            }}
            fontSize={{
              xl: '50px',
              lg: '3xl',
              md: '2xl',
              sm: '26px',
            }}
            textAlign={['center']}
            variant="white"
            color="#EDE7E1"
            fontFamily="Signifier"
            fontWeight="500"
            height={{
              sm: '32px',
              md: 'inherit',
            }}
          >
            Evolve beyond stress
          </Heading>
          <CommonText
            classes={`text-center mb-6 px-10
            sm:width-[300px] sm:text-[16px]
            md:width-full
            lg:width-[539px] lg:mb-12
            `}
          >
            TUNE is performance tech for your nervous system powered by
            medical-grade vibrations that literally tune the body to its most
            optimal state in 15 minutes, so you can not only survive but thrive
            in modern times.
          </CommonText>
          <NextLink href="/locations" passHref>
            <button className="px-6 py-3 uppercase transition-colors border font-scto text-naturalWhite border-primary border-primary-700 hover:bg-primary">
              Book a session
            </button>
          </NextLink>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HomeHero
