import React from 'react'

const Button = (props: any) => (
  <button
    {...props}
    className={`${props.className} ${props.disabled && `bg-disabled`}`}
    disabled={props.disabled}
  />
)

export default Button
