import React, { Fragment, useEffect, useState, useRef } from 'react'

export const CustomMarquee = ({
  style = {},
  className = '',
  play = true,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = 'left',
  speed = 20,
  children,
}) => {
  const [containerWidth, setContainerWidth] = useState(0)
  const [marqueeWidth, setMarqueeWidth] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isMounted, setIsMounted] = useState(false)
  const [childs, setChilds] = useState(null)

  const containerRef = useRef(null)
  const marqueeRef = useRef(null)

  const calculateWidth = () => {
    // Find width of container and width of marquee
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width)
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width)
    }

    if (marqueeWidth < containerWidth) {
      setDuration(containerWidth / speed)
      const n = Math.ceil(containerWidth / marqueeWidth)
      var arr = []
      for (var i = 0; i < n; i++) {
        arr.push(...children)
      }

      setChilds(
        arr.map((ch, i) => (
          <span
            key={i}
            className="text-element"
            style={{ fontStyle: i % 2 ? 'normal' : 'italic' }}
          >
            {ch}
          </span>
        ))
      )
    } else {
      setDuration(marqueeWidth / speed)
    }
  }

  useEffect(() => {
    calculateWidth()
    // Rerender on window resize
    window.addEventListener('resize', calculateWidth)
    return () => {
      window.removeEventListener('resize', calculateWidth)
    }
  })

  useEffect(() => {
    setChilds(
      children.map((ch, i) => (
        <span
          key={i}
          className="text-element"
          style={{ fontStyle: i % 2 ? 'normal' : 'italic' }}
        >
          {ch}
        </span>
      ))
    )

    setIsMounted(true)
  }, [])

  return (
    <Fragment>
      {!isMounted ? null : (
        <div
          ref={containerRef}
          style={{
            ...style,
            '--pause-on-hover': pauseOnHover ? 'paused' : 'running',
            '--pause-on-click': pauseOnClick ? 'paused' : 'running',
          }}
          className={className + ' marquee-container'}
        >
          <div
            ref={marqueeRef}
            style={{
              '--play': play ? 'running' : 'paused',
              '--direction': direction === 'left' ? 'normal' : 'reverse',
              '--duration': `${duration}s`,
            }}
            className="marquee"
          >
            {childs}
          </div>
          <div
            style={{
              '--play': play ? 'running' : 'paused',
              '--direction': direction === 'left' ? 'normal' : 'reverse',
              '--duration': `${duration}s`,
            }}
            className="marquee"
          >
            {childs}
          </div>
        </div>
      )}
    </Fragment>
  )
}
