import { Flex, Heading, Text } from '@chakra-ui/react'

const TextSection = () => {
  return (
    <Flex
      direction={['column']}
      align="center"
      justify="center"
      pt={[50, 50, 100, 100]}
      pb={[150, 50, 50, 50]}
      px={[4, 10, 0]}
    >
      <Heading
        color="#45221D"
        mb={[6, 6, 8]}
        fontSize={['2xl', '2xl', '4xl', '50px']}
        fontWeight={['500']}
        textAlign="center"
      >
        Performance tech for your nervous system
      </Heading>
      {/* TODO: detemrine breakpoints for CommonText */}
      <Text
        color="#45221D"
        w={['100%', '100%', '80%', '80%', '60%', '40%']}
        textAlign="center"
        fontSize={['md', 'md', 'xl', '2xl']}
      >
        No matter what you come to the table with, TUNE recalibrates your entire
        nervous system with medical-grade vibrations proven by the NIH and Mayo
        Clinic to reduce stress by 54%, improve sleep and maintain your edge.
      </Text>
    </Flex>
  )
}

export default TextSection
