import GuyRelaxingSrc from 'public/images/home/guy-relaxing.jpg'
import GymSrc from 'public/images/home/home-gym.png'
import StudioSrc from 'public/images/studio/studio.png'
import Image from 'next/image'
import { useRouter } from 'next/router'

import Button from '../../components/Button/Button'

import type { StaticImageData } from 'next/image'

interface ImageWithTextProps {
  title: string
  titleSecondary: string
  text: string
  image: StaticImageData
  imagePosition?: 'left' | 'right'
  bgColor?: string
  textColor?: string
  button?: {
    text: string
    action: string
    textColor?: string
  }
  buttonSecondary?: {
    text: string
    action: string
    textColor?: string
  }
  logos?: string[]
}

const ImageWithTextList = () => {
  const router = useRouter()

  const data: ImageWithTextProps[] = [
    {
      title: 'TUNE',
      titleSecondary: 'Studio',
      text: 'Book a session at one of our partner locations in New York, Chicago and more.',
      image: StudioSrc,
      imagePosition: 'right',
      bgColor: 'bg-secondary',
      textColor: 'text-white',
      button: {
        text: 'Book a session',
        action: '/tune-studio',
        textColor: 'text-white',
      },
      buttonSecondary: {
        text: 'Locations',
        action: '/locations',
        textColor: 'text-white',
      },
    },
    {
      title: 'TUNE',
      titleSecondary: 'for Home',
      text: 'Peace of mind accessed with ease at home.',
      image: GymSrc,
      imagePosition: 'left',
      bgColor: 'bg-white',
      textColor: 'text-secondary',
      button: {
        text: 'Buy a Tune',
        action: '/products',
        textColor: 'text-primary',
      },
    },
    {
      title: 'TUNE',
      titleSecondary: 'for Work',
      text: 'Increase your team’s productivity. TUNE is proven by science, trusted by Fortune 500s.',
      image: GuyRelaxingSrc,
      imagePosition: 'right',
      bgColor: 'bg-evergreen',
      textColor: 'text-white',
      button: {
        text: 'Learn More',
        action: '/tune-work',
        textColor: 'text-white',
      },
      logos: ['bg-google', 'bg-marriott', 'bg-luluLemon', 'bg-northWell'],
    },
  ]

  return (
    <>
      <div className="">
        {data.map(
          (
            {
              text,
              title,
              titleSecondary,
              button,
              buttonSecondary,
              logos,
              image,
              imagePosition,
              bgColor,
              textColor,
            },
            index
          ) => (
            <div
              key={index}
              className="sm:flex sm:flex-col md:grid md:grid-cols-2 md:h-[540px]"
            >
              {imagePosition === 'left' && (
                <div className="col-span-1">
                  <div
                    className={`flex justify-center w-full bg-center bg-cover md:h-full relative`}
                  >
                    <Image
                      alt={text}
                      src={image}
                      layout="fill"
                      objectFit="cover"
                      placeholder="blur"
                    />
                  </div>
                </div>
              )}
              <div
                className={`flex flex-col items-center justify-center sm:m-auto h-full ${bgColor} ${textColor} w-full`}
              >
                <div className="flex flex-col justify-between w-full h-full p-10 md:p-16">
                  <div>
                    <div className="flex col-span-1 pt-3 pb-5">
                      <div className="pr-2 text-4xl font-extrabold font-scto">
                        {title}
                      </div>
                      <div className="text-4xl italic"> {titleSecondary}</div>
                    </div>
                    <p className="py-8 text-xl">{text}</p>
                  </div>
                  {logos && (
                    <div className="flex py-10 lg:justify-center">
                      {logos.map((logo, index) => (
                        <div
                          key={index}
                          className={`flex justify-center bg-center mx-2 h-[30px] ${logo} w-full bg-no-repeat bg-contain`}
                        />
                      ))}
                    </div>
                  )}
                  <div className="flex flex-col pt-5 lg:flex-row">
                    {button && (
                      <Button
                        className={`px-10 py-4 font-semibold ${button.textColor} uppercase border font-scto font-xl hover:${button.textColor} border-primary hover:bg-primary hover:text-naturalWhite transition-colors border-primary-700 hover:border-transparent`}
                        onClick={() => router.push(button.action)}
                      >
                        {button.text}
                      </Button>
                    )}
                    {buttonSecondary && (
                      <Button
                        className={`px-10 py-4 mt-5 lg:mt-0 lg:ml-10 font-semibold ${buttonSecondary.textColor} uppercase border font-scto font-xl hover:${buttonSecondary.textColor} border-primary hover:bg-primary transition-colors border-primary-700 hover:border-transparent`}
                        onClick={() => router.push(buttonSecondary.action)}
                      >
                        {buttonSecondary.text}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              {imagePosition === 'right' && (
                <div className="col-span-1">
                  <div
                    className={`flex justify-center w-full bg-center bg-cover md:h-full relative`}
                  >
                    <Image
                      alt={text}
                      src={image}
                      layout="fill"
                      objectFit="cover"
                      placeholder="blur"
                    />
                  </div>
                </div>
              )}
            </div>
          )
        )}
      </div>
    </>
  )
}

export default ImageWithTextList
