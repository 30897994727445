import Image from 'next/image'
import Link from 'next/link'

import TUNEEImageSrc from '../../public/images/home/TUNEE.png'

import { Button, Flex, Text } from '@chakra-ui/react'

import HowTuneCard, {
  HowTuneCardProps,
} from '../../components/cards/HowTuneCard'
import Container from '../../components/container'

const cards: HowTuneCardProps[] = [
  {
    image: {
      href: '/images/icons/1@2x.png',
      alt: 'icon',
    },
    title: 'Powerful medical-grade frequencies',
    content:
      'Vibroacoustic technology developed for patient treatment, in its first-ever commercial application.',
  },
  {
    image: {
      href: '/images/icons/2@2x.png',
      alt: 'icon',
    },
    title: 'Originally-scored therapeutic neuromusic',
    content:
      'Specifically composed by medical musicians to calm racing minds and deepen your experience.',
  },
  {
    image: {
      href: '/images/icons/3@2x.png',
      alt: 'icon',
    },
    title: 'Designed by engineers, built by hand',
    content:
      'State-of-the-art sustainable construction modeled after a violin focuses vibrations so they’re 100% absorbed into the body.',
  },
]

const HowTune = () => {
  return (
    <Flex
      bgColor="#EDE7E1"
      direction="column"
      justify="center"
      align="center"
      position="relative"
      pt={[0, 0, 0]}
      pb={[0, 0, 0]}
    >
      <Container
        flexDirection={{
          sm: 'column',
        }}
        alignItems={{
          md: 'center',
        }}
      >
        <div className="w-full lg:w-3/4 h-[300px] md:h-full">
          <div className="flex relative justify-center bg-center bg-cover h-[220px] md:h-[465px]">
            <Image
              alt="Visual of a TUNE bed"
              src={TUNEEImageSrc}
              layout="fill"
              objectFit="cover"
              placeholder="blur"
            />
          </div>
        </div>
        <Flex
          direction={['column']}
          align={['center']}
          justify={['center']}
          mt={[0, 0, 0]}
          width="100%"
        >
          <Flex
            justify={['space-between']}
            direction={['column', 'column', 'row']}
            align={['center', 'center', 'flex-start', 'space-between']}
            position="relative"
            bottom={['50px', '80px', '-50px', '-80px', '-50px']}
            width="100%"
            minWidth="100%"
            paddingRight={{
              xl: '0',
              lg: '30px',
            }}
            paddingLeft={{
              xl: '0',
              lg: '30px',
            }}
          >
            {cards.map((card: HowTuneCardProps, index: number) => (
              <HowTuneCard key={index} index={index} {...card} />
            ))}
          </Flex>
        </Flex>
        <Flex
          direction={['column']}
          align={['center']}
          justify={['center']}
          marginTop={{
            sm: '-60px',
            md: '0',
          }}
        >
          <Link href="/our-tech" passHref>
            <button className="px-6 py-3 mt-16 uppercase transition-colors border font-scto text-primary border-primary border-primary-700 hover:bg-primary hover:text-naturalWhite">
              How tune works
            </button>
          </Link>
          <Text
            color="#989898"
            fontStyle="italic"
            fontSize={['lg']}
            mt={{
              md: 10,
              sm: '15px',
            }}
            marginTop="32px"
            marginBottom={{
              sm: '60px',
            }}
          >
            Patents pending
          </Text>
        </Flex>
      </Container>
    </Flex>
  )
}

export default HowTune
