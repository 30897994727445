import { Flex, Heading, Box } from '@chakra-ui/react'
import { LazyImage } from '../components'

export type BgImageWithTextProps = {
  text: string
  image: {
    src: string
    placeholder: string
    alt?: string
  }
}

const BgImageWithText = ({ text, image }: BgImageWithTextProps) => {
  return (
    <Flex
      h={['40vh', '40vh', '50vh', '60vh', '80vh']}
      justify="center"
      align="center"
      position="relative"
    >
      <Box position="absolute" top="0" left="0" width="100%" height="100%">
        <LazyImage
          src={image.src}
          placeholder={image.placeholder}
          alt={image.alt}
        />
      </Box>
      <Heading
        color="#FFFFFF"
        fontSize={['4xl', '4xl', '7xl']}
        textAlign="center"
        w={['60%']}
        fontWeight="500"
        position="relative"
      >
        {text}
      </Heading>
    </Flex>
  )
}

export default BgImageWithText
