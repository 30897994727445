import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import SwiperCore, {
  A11y,
  Autoplay,
  Controller,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Button, Flex, Link, Text } from '@chakra-ui/react'

import content from './carouselContent'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay, Pagination, Scrollbar, A11y, Controller])

const HomeCarousel = () => {
  // Add an action to each content item to display in the carousel
  const decoratedContent = content.map((content) => ({
    ...content,
  }))

  return (
    <Flex
      bgColor="#EDE7E1"
      pb={[10, 10, 10, 20]}
      justify="center"
      align="center"
      paddingX={{
        sm: '5px',
        md: '24px',
        lg: '54px',
      }}
    >
      <Flex
        border="1px solid #CC724F"
        align="center"
        justify="center"
        color="#CC724F"
        _hover={{
          color: 'white',
          bgColor: '#CC724F',
        }}
        className="swiper-home-recipes-prev swiper-home-recipes-disabled"
        transform={{
          md: 'none',
          sm: 'translateX(100%)',
        }}
        zIndex={888}
        width={{
          sm: '36px',
          md: '65px',
        }}
        height={{
          sm: '36px',
          md: '65px',
        }}
        padding={{
          sm: 0,
        }}
      >
        <MdArrowBackIos
          size={30}
          style={{
            transform: 'translateX(5px)',
          }}
        />
      </Flex>
      <Flex w={['90%']}>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          // autoplay={{ delay: 10000 }}
          navigation={{
            nextEl: '.swiper-home-recipes-next',
            prevEl: '.swiper-home-recipes-prev',
            disabledClass: 'swiper-home-recipes-disabled',
          }}
        >
          {decoratedContent.map((item, index) => (
            <SwiperSlide key={index}>
              <Flex
                direction="column"
                align="center"
                justify="center"
                w="100%"
                h="100%"
                pb={[0, 0, 10]}
                px={[8, 8, 10]}
              >
                {/* TODO: determine how breakpoints will work with CommonText & dangerouslySetInnerHTML prop */}
                <Text
                  fontSize={['md', '16px', 'xl']}
                  w={['80%', '80%', '70%', '60%', '40%']}
                  color="#45221D"
                  textAlign="center"
                  mb={[8]}
                >
                  {item.content}
                </Text>

                <Text
                  fontSize="sm"
                  color="#45221D"
                  textAlign="center"
                  fontFamily="Scto Grotesk A"
                  fontWeight="bold"
                  textTransform="uppercase"
                  dangerouslySetInnerHTML={{ __html: item.author }}
                />

                <Link
                  href={'/our-tech'}
                  color="#CC724F"
                  textAlign="center"
                  display={['none', 'none', 'initial']}
                >
                  <button className="px-6 py-3 mt-10 uppercase transition-colors border font-scto text-primary border-primary border-primary-700 hover:bg-primary hover:text-naturalWhite">
                    {item.button}
                  </button>
                </Link>
              </Flex>
            </SwiperSlide>
          ))}
        </Swiper>
      </Flex>

      <Flex
        border="1px solid #CC724F"
        w={['100%']}
        py={[8]}
        align="center"
        justify="center"
        color="#CC724F"
        _hover={{
          color: 'white',
          bgColor: '#CC724F',
        }}
        className="swiper-home-recipes-next swiper-home-recipes-disabled"
        transform={{
          md: 'none',
          sm: 'translateX(-100%)',
        }}
        zIndex={888}
        width={{
          sm: '36px',
          md: '65px',
        }}
        height={{
          sm: '36px',
          md: '65px',
        }}
        padding={{
          sm: 0,
        }}
      >
        <MdArrowForwardIos size={30} />
      </Flex>
    </Flex>
  )
}

export default HomeCarousel
