import type { NextPage } from 'next'
import Head from 'next/head'
import BgImageWithText from '../components/BgImageWithText'
import HomeCarousel from '../containers/home/HomeCarousel'
import HomeHero from '../containers/home/HomeHero'
import HowTune from '../containers/home/HowTune'
import ImageWithTextList from '../containers/home/ImageWithTextList'
import TextSection from '../containers/home/TextSection'
import Layout from '../containers/Layout'
import { CustomMarquee } from '../components/CustomMarquee'

const Home: NextPage = () => {
  const textList = [
    'Feel better.',
    'Work better.',
    'Sleep better.',
    'Communicate better.',
    'Function better.',
    'Digest better.',
    'Parent better.',
    'Relax better.',
    'Treat yourself + others better.',
    'Do life better.',
  ]

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="public/images/touch/apple-touch-icon.png"
        ></link>
        <link rel="manifest" href="site.webmanifest"></link>
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1.0,user-scalable=no"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://staytuned.co" />
        <meta
          property="og:title"
          content="Performance tech for your nervous system"
        />
        <meta
          property="og:description"
          content="Recalibrate your nervous system in 15 minutes with medical-grade vibrations scientifically proven to reduce stress, improve sleep and maintain your edge."
        />
        <meta
          property="og:image"
          content="https://staytuned.co/_next/image?url=%2Fimages%2Fhome%2FTUNE-Opengraph-home-01.jpg&w=1200&q=75"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://staytuned.co" />
        <meta
          property="twitter:title"
          content="Performance tech for your nervous system"
        />
        <meta
          property="twitter:description"
          content="Recalibrate your nervous system in 15 minutes with medical-grade vibrations proven to reduce stress, improve sleep and maintain your edge."
        />
        <meta
          property="twitter:image"
          content="https://staytuned.co/_next/image?url=%2Fimages%2Fhome%2FTUNE-Opengraph-home-01.jpg&w=1200&q=75"
        />
      </Head>
      <Layout>
        <HomeHero />
        <TextSection />
        <HowTune />
        <CustomMarquee
          speed={50}
          style={{
            height: '71px',
            backgroundColor: '#E2E7E1',
          }}
        >
          {textList}
        </CustomMarquee>
        <ImageWithTextList />
        <div className="flex justify-center py-10 text-4xl text-center bg-white text-secondary front-scto h-fit">
          <h1>Loved by everybody it touches</h1>
        </div>
        <HomeCarousel />
        <BgImageWithText
          text="If humans had a reset button, this would be it"
          image={{
            placeholder: '/images/home/aerial_placeholder.jpg',
            src: '/images/home/aerial.jpg',
            alt: 'aerial view of the city',
          }}
        />
      </Layout>
    </>
  )
}

export default Home
