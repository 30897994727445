import { ClassAttributes, HTMLAttributes } from 'react'

type HTMLProps<T> = ClassAttributes<T> & HTMLAttributes<T>

interface CommonTextProps extends HTMLProps<HTMLElement> {
  classes?: string
  children: React.ReactNode
}

const CommonText = ({ classes, children }: CommonTextProps): JSX.Element => (
  <p className={classes}>{children}</p>
)

export default CommonText
