export default [
  {
    content: `Once you find out what your parasympathetic system
		feels like, you’re going to want to feel like that all the time. I’ve tuned so many times and it’s my favorite feeling.`,
    author: `Biet Simkin,
		<br />
		Meditation leader & best-selling author`,
    button: 'Experience tune',
  },
  {
    content: `I was able to maximize my time and get right into the flow.`,
    author: `Chris Algieri,
		<br />
		Professional boxer`,
    button: 'Experience tune',
  },
  {
    content: `Mind-bendingly good.`,
    author: `Danielle Kayembe,
		<br />
		Nike Innovations`,
    button: 'Experience tune',
  },
  {
    content: `I'm not usually the meditation type of guy, but I fell right into the zone the first time I tuned.`,
    author: `Lance Armstrong`,
    button: 'Experience tune',
  },
  {
    content: `A god-send. In just 5 minutes I felt a deep sense of grounding, homeostasis and relaxation.`,
    author: `Christine Bullock,
		<br />
		Founder of KAYO Beauty`,
    button: 'Experience tune',
  },
  {
    content: `I was obsessed with TUNE after the first session.`,
    author: `Hayley Caddes,
		<br />
	 Founder and CEO of Chill Pill`,
    button: 'Experience tune',
  },
  {
    content: `All the troubles of the day went away and I felt light and airy afterwards.`,
    author: `Biz Colette,
		<br />
		Recording Artist`,
    button: 'Experience tune',
  },
  {
    content: `It was the most relaxing meditative experience I have ever had.`,
    author: `Jess Clarke,
		<br />
		Founder of SweatNet`,
    button: 'Experience tune',
  },
  {
    content: `If I could carry this around with me, I would.`,
    author: `Dr. Medhi Ettehadulhagh,
		<br />
		Founder of VOTwear`,
    button: 'Experience tune',
  },
  {
    content: `At Google we have TUNE to help people clear their mind and de-stress. I personally think this is the future of work.`,
    author: `Ivy Ross,
		<br />
		SVP of Hardware, Google`,
    button: 'Experience tune',
  },
]
