import { Flex, Heading, Text, Image } from '@chakra-ui/react'
import CommonText from '../CommonText'

export type HowTuneCardProps = {
  image: {
    href: string
    alt: string
  }
  title: string
  content: string
  index?: number
}

const HowTuneCard = ({
  index = 0,
  image,
  title,
  content,
}: HowTuneCardProps) => {
  return (
    <Flex
      direction={['column']}
      align="center"
      justify="center"
      textAlign="center"
      w={['90%', '80%', '100%']}
      maxWidth="375px"
      my={[8, 8, 0]}
      paddingLeft={{
        lg: 0,
        md: '15px',
      }}
      paddingRight={{
        lg: 0,
        md: '15px',
      }}
    >
      <Flex
        minHeight={{
          md: '80px',
          sm: '37px',
        }}
        width={{
          md: '102px',
        }}
      >
        <Image
          src={image.href}
          maxW={{
            sm: index < 2 ? '75px' : '102px',
          }}
          h="100%"
          w="100%"
          alt={image.alt}
        />
      </Flex>
      <Heading
        fontSize={{
          lg: '2xl',
          md: '20px',
          sm: '21px',
        }}
        mt={[8]}
        fontWeight="400"
        textColor={'#45221D'}
        fontFamily="GreyCliffCF"
        w={{
          lg: '80%',
          md: '100%',
        }}
      >
        {title}
      </Heading>
      <CommonText classes="sm:font-[15px] md:font-[15px] mt-4 font-greycliff text-secondary">
        {content}
      </CommonText>
    </Flex>
  )
}

export default HowTuneCard
