import { Flex } from '@chakra-ui/react'

const Container = ({ children, ...rest }: any) => {
  return (
    <Flex maxW="1270px" width="100%" margin="0 auto" {...rest}>
      {children}
    </Flex>
  )
}

export default Container
